/**
 * Terms and Conditions
 */
import React from 'react'
import { mq } from '../../../hooks'
import { Layout } from '../../../templates'
import {
  Body,
  TitleLarge,
  UnorderedList,
  UnorderedListItem,
  TitleMedium,
} from '../../../components/RichText'
import SEO from '../../../templates/Layout/SEO'

export default () => (
  <Layout>
      <SEO
          title="Terms Of Use"
          description="Understand the terms of use for Accolade services and products."
      />
      <div css={mq({ margin: ['1rem 1rem', '1rem 6rem'] })}>
        <TitleLarge>Accolade Terms of Use</TitleLarge>
        <Body>
          <strong>IMPORTANT</strong>
        </Body>
        <Body>
          This site, www.accolade.com (“<strong>Site</strong>“), and/or any other
          Accolade owned websites which are linked from this Site, contains
          information produced by and/or for Accolade, Inc. and its affiliates (“
          <strong>Accolade</strong>”). By using the Site, you are agreeing to the
          following terms of use (“
          <strong>Terms of Use</strong>“). Please read them carefully.
        </Body>
        <Body>
          This Site is not directed at anyone under the age of 18, and does not
          seek to obtain information from any person under the age of 18.&nbsp; By
          using this Site, you are attesting to the fact that you are at least 18
          years of age, or older and possess the legal rights and ability to agree
          to these Terms of Use.
        </Body>
        <Body>
          These Terms of Use apply to the Site generally and are subject to any
          other agreement between you and Accolade and such terms of use, legal
          notices or disclaimers applicable to specific portions of the Site
          accessed by you.
        </Body>
        <Body>
          These Terms of Use were last updated as of <strong>October 2020</strong>
          . Accolade may revise these Terms of Use at any time without notice. Any
          revision and/or addition to these Terms of Use shall become effective
          and binding on you when you continue to use the Site on or after the
          effective date of such revision and/or addition. Know that we will never
          make changes to these Terms that diminish the protection of your
          personal information or are inconsistent with applicable law.
        </Body>
        <Body>
          <strong>PRIVACY POLICY</strong>
        </Body>
        <Body>
          Accolade may monitor your usage of the Site. You can view Accolade’s
          Privacy Policy at{' '}
          <a href="https://www.accolade.com/legal/policies/privacy">
            https://www.accolade.com/legal/policies/privacy
          </a>
          . By accessing the Site, you agree that Accolade may disclose any
          information as may be collected from you by Accolade or any third party
          providing services to Accolade. Accolade will comply with all laws and
          regulations which apply to our collection, retention and use of
          information pertaining to you, and we shall take all practicable steps
          to ensure that any information collected from you is protected against
          unauthorized or accidental access, erasure or use.
        </Body>
        <Body>
          <strong>ACCOLADE’S WEB AND MOBILE SERVICES</strong>
        </Body>
        <Body>
          In addition to this Site, Accolade provides, from time to time, various
          web and mobile services to its customers and clients.&nbsp; Such web and
          mobile services may have their own terms and conditions, and your use of
          such services is subject to any such additional terms and conditions.
        </Body>
        <Body>
          One such service is access to our “MyAccolade” secure website
          (www.myaccolade.com). MyAccolade is just one more way you can connect
          with your Accolade Health Assistant® to support you and your family in
          your healthcare journey to get to the right care at the right time.
        </Body>
        <Body>
          <a href="https://www.accolade.com/legal/policies/privacymobile-terms-use/">
            Mobile Terms and Conditions
          </a>
          <br />
          <a href="https://www.accolade.com/legal/policies/digital-tools/">
            Mobile Privacy Policy
          </a>
        </Body>
        <Body>
          <strong>INFORMATION PURPOSES ONLY</strong>
        </Body>
        <Body>
          The Site has been prepared solely for information purposes, and your use
          of the Site does not entitle you to any product or service offered by
          Accolade.&nbsp; If your employer or health plan is an Accolade customer,
          please call your Accolade Health Assistant® who will be happy to assist
          you. The Site contains general information only and does not take into
          account any particular person’s health or situation. All information,
          opinions and estimates expressed on the Site are current as of the date
          of publication, are subject to change without notice, and may become
          outdated over time.
        </Body>
        <Body>
          The opinions expressed on the Site are non-binding and may not represent
          the actual terms and conditions of Accolade services.&nbsp; Statements
          or opinions on this Site do not constitute medical, psychological, or
          other professional advice.&nbsp; Contact your doctor or nurse if you
          need non-emergency medical care.
        </Body>
        <Body>
          Accolade is here to simplify healthcare and your health benefits for you
          and your family. We are here to help you understand your healthcare
          options, to facilitate your use of the healthcare system and to support
          you as you make your healthcare decisions. Accolade does not provide
          medical advice, provide nursing care, diagnose or deliver treatment.
          Those are activities for you to undertake with your doctor or other
          appropriately trained and qualified health professional.
        </Body>
        <Body>
          <strong>
            IF YOU THINK YOU HAVE A MEDICAL EMERGENCY, YOU SHOULD CALL YOUR DOCTOR
            OR 911 IMMEDIATELY.
          </strong>
        </Body>
        <Body>
          <strong>NO RELIANCE</strong>
        </Body>
        <Body>
          While Accolade has endeavored to ensure the accuracy of the information
          on the Site, Accolade does not guarantee or give any representation or
          warranty as to the non-infringement, accuracy, timeliness or
          completeness of any information, published third-party content or
          material published on the Site. ALL SITE INFORMATION IS PROVIDED “AS IS”
          AND WITHOUT WARRANTY OF ANY KIND.&nbsp; Accordingly, you should not
          place reliance on Site content. In relation to third-party content,
          Accolade may not have independently verified the accuracy or
          completeness of such information or the assumptions on which such
          information is based.
        </Body>
        <Body>
          <strong>NO REPRESENTATION OR WARRANTY</strong>
        </Body>
        <Body>
          THIS SITE IS PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT ANY
          REPRESENTATION OR WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, TO
          THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, INCLUDING BUT
          NOT LIMITED TO REPRESENTATIONS OR WARRANTIES OF NON-INFRINGEMENT OF
          THIRD PARTY RIGHTS (INCLUDING BUT NOT LIMITED TO INTELLECTUAL PROPERTY
          RIGHTS), TITLE, MERCHANTABILITY, SATISFACTORY QUALITY, OR FITNESS FOR A
          PARTICULAR PURPOSE.
        </Body>
        <Body>
          Materials on the Site may be subject to incorrect data transmission or
          other faults due to the public nature of the Internet. Accolade may
          amend, relocate, remove or delete any such materials from time to time
          in its sole discretion.
        </Body>
        <Body>
          Without prejudice to the foregoing, and to the extent permitted by
          applicable law, Accolade makes no representations or warranties:
        </Body>
        <UnorderedList>
          <UnorderedListItem>
            regarding the accuracy, timeliness, adequacy, commercial value or
            completeness of any data and/or information contained on the Site;
          </UnorderedListItem>
          <UnorderedListItem>
            that the Site is free from computer viruses or other malicious,
            destructive or corrupting codes, agents, programs or macros; or
          </UnorderedListItem>
          <UnorderedListItem>
            that the functions contained on the Site will not be interrupted, or
            error-free or that defects will be corrected.
          </UnorderedListItem>
        </UnorderedList>
        <Body>
          Accolade accepts no responsibility or liability in relation to such
          matters.
        </Body>
        <Body>
          To the extent permitted by applicable law, in no event will Accolade be
          liable for the published content of any third party which is available
          on the Site or any direct, special, indirect, consequential, incidental
          damages or any other damages of any kind, including damages caused by
          gross negligence or willful misconduct arising out of your use of the
          Site.
        </Body>
        <Body>
          <strong>LIMITATION OF LIABILITY</strong>
        </Body>
        <Body>
          YOU UNDERSTAND AND AGREE THAT ACCOLADE LIMITS ITS LIABILITY IN
          CONNECTION WITH YOUR USE OF THE SITE AND SHALL NOT BE LIABLE FOR ANY
          DAMAGES OR LOSS OF ANY KIND, INCLUDING, WITHOUT LIMITATION, ANY DIRECT,
          INDIRECT, SPECIAL, CONSEQUENTIAL, INCIDENTAL OR PUNITIVE DAMAGES. IF YOU
          ARE DISSATISFIED WITH THE SITE OR ANY OF THE CONTENT, YOUR SOLE AND
          EXCLUSIVE REMEDY IS TO DISCONTINUE ACCESSING AND USING THE SITE AND THE
          CONTENT.
        </Body>
        <Body>
          NOTWITHSTANDING THE ABOVE LIMITATION OF LIABILITY, AND TO THE MAXIMUM
          EXTENT PERMITTED BY LAW, YOU AGREE THAT THE MAXIMUM AMOUNT OF ACCOLADE’S
          LIABILITY, IF ANY, RELATED TO YOUR USE OF THIS SITE SHALL BE CAPPED AT
          ONE-HUNDRED UNITED STATES DOLLARS ($100.00).
        </Body>
        <Body>
          <strong>LOCAL LAWS AND REGULATIONS</strong>
        </Body>
        <Body>
          Accolade services are generally available only within the United States
          and this Site is not directed at persons who may access the Site via the
          Internet from locations outside the United States.
        </Body>
        <Body>
          Accolade does not represent that any of the materials, opinions or other
          content on the Site are appropriate for use or permitted by local laws
          in all jurisdictions. Those who access the Site do so on their own
          initiative, and are responsible for compliance with applicable local
          laws or regulations; legal advice should be sought in cases of doubt. If
          you are prohibited from accessing websites, such as the Site, from your
          location, you should not access the Site, and Accolade take no
          responsibility for such prohibited access.
        </Body>
        <Body>
          <strong>INTELLECTUAL PROPERTY</strong>
        </Body>
        <Body>
          All right, title and interest in the Site is the exclusive property of
          Accolade except as otherwise stated.
        </Body>
        <Body>
          You may not copy, reproduce, recompile, decompile, disassemble, reverse
          engineer, distribute, publish, display, perform, modify, and upload to
          create derivative works from, create derivative works from, transmit, or
          in any way exploit any part of the Site.
        </Body>
        <Body>
          All trademarks, service marks, trade names, brand features and logos
          displayed on this Site are proprietary to Accolade, except as explicitly
          noted. Nothing contained on this Site should be construed as granting,
          by implication, estoppel or otherwise, any license or right to use any
          trademark displayed on this Site without the written permission of
          Accolade or such other third party that may own the trademark displayed
          on this Site. Your use of the trademarks displayed on this Site, except
          as provided in these Terms of Use, is strictly prohibited.
        </Body>
        <Body>
          Any unauthorized use of the trademarks, service marks, trade names,
          brand features and logos displayed on this Site may violate copyright,
          trademark or other intellectual property laws, and the laws of privacy
          and publicity and communications. If you download any information from
          this Site, you agree that you will not copy it or remove or obscure any
          copyright or other notices or legends contained in any such information.
        </Body>
        <Body>
          Additionally, you may not offer any part of the contents of the Site for
          sale or distribute such part over any medium, including, but not limited
          to, distribution by television or radio broadcast, or distribution on a
          computer network. You may not make any part of the Site available as
          part of another website, whether by scraping, robotic or web-bot access,
          hyperlink framing on the Internet or otherwise. The Site and the
          information, commentary and materials contained therein may not be used
          to construct a database of any kind. You shall not store the Site (in
          its entirety or in any part) in databases for access by you or any third
          party or distribute any database sites containing all or part of the
          Site.
        </Body>
        <Body>
          Subject to your strict compliance with these Terms of Use, Accolade
          grants you a limited, personal, non-exclusive, non-commercial,
          revocable, non-assignable and non-transferable license to access,
          download, view, use the content and information on the Site. You must
          retain all copyright, trademark and other proprietary notices contained
          on the Site. You have no other rights or interests, and you will not
          permit any improper or unauthorized third-party use or access.
        </Body>
        <Body>
          If you believe that any content on the Site contains content that
          violate your rights or has been copied in a way that constitutes a
          copyright infringement, please let Accolade know immediately at the
          address listed below.
        </Body>
        <Body>
          <strong>PROHIBITED USES</strong>
        </Body>
        <Body>You are prohibited from:</Body>
        <UnorderedList>
          <UnorderedListItem>
            using this Site in any manner that could damage or overburden any
            Accolade server, or any network connected to any Accolade server, as
            all servers have limited capacity and are used by many people;
          </UnorderedListItem>
          <UnorderedListItem>
            using this Site in any manner that would interfere with another
            party’s use of the Site;
          </UnorderedListItem>
          <UnorderedListItem>
            including the term ACCOLADE®, ACCOLADE HEALTH ASSISTANT®, or any
            Accolade trademark or executive’s name or any variation of the
            foregoing, as a meta-tag, hidden textual element;
          </UnorderedListItem>
          <UnorderedListItem>
            using any robot, spider, intelligent agent, other automatic device or
            manual process to search, monitor or copy this Site or the reports,
            data, information, content, software, products, services or other
            materials on, generated by or obtained from the Site, whether through
            links or otherwise (collectively “Devices”) without Accolade’s
            permission, provided that generally available third party web browsers
            may be used without such permission;
          </UnorderedListItem>
          <UnorderedListItem>
            using this Site or the Devices in any manner that could create an
            impression of affiliation, sponsorship or endorsement by Accolade or
            any of its customers;
          </UnorderedListItem>
          <UnorderedListItem>
            using the Site for any unlawful, illegal or prohibited purpose;
          </UnorderedListItem>
          <UnorderedListItem>
            using the Site to damage or attempt to do harm to others; or
          </UnorderedListItem>
          <UnorderedListItem>
            uploading or posting statements or information that would be
            reasonably understood to be offensive, obscene, constitute
            pornography, illegal content, or any of the like.
          </UnorderedListItem>
        </UnorderedList>
        <Body>
          If you do any harm by your wrongful actions in using the Site, you agree
          to pay Accolade or any other party associated with Accolade for any
          losses, damages or costs, resulting from your failure to comply with
          these Terms of Use.
        </Body>
        <Body>
          <strong>LINKS TO AND FROM THE SITE</strong>
        </Body>
        <Body>
          Third party sites or pages that are linked to the Site are for
          information purposes only and may not have been reviewed or verified by
          Accolade. Accolade has no responsibility for the content of the third
          party sites or pages linked to or from the Site and no affiliation,
          sponsorship, approval, verification, investigation, monitoring or
          endorsement of any such sites or pages or of their content is
          implied.&nbsp;
          <strong>
            Following links to or from any other sites or pages shall be at your
            own risk
          </strong>
          <strong>
            &nbsp;and Accolade accepts no responsibility or liability, to the
            extent permitted by any law, for any losses or penalties whatsoever
            that may be incurred as a result of any linking to any location on any
            linked sites or pages.
          </strong>
        </Body>
        <Body>
          <strong>SMS TEXT MESSAGING</strong>
        </Body>
        <Body>
          The Accolade service enables communication by means of SMS text messages
          to and from your mobile device, if you elect to receive SMS messages.
        </Body>
        <Body>
          You may text TNC to 67793 to receive a link to download the mobile app
          or ask your Accolade Health Assistant to have a link sent to you via
          SMS. To stop the SMS text service at any time, text STOP to 67793. To
          receive help information, text HELP to 67793 or reply HELP to any
          message or email: member.support@accolade.com.
        </Body>
        <Body>Carriers are not liable for delayed or undelivered messages.</Body>
        <Body>
          Consent to receive SMS text messages is not required as a condition of
          registering for or using the Accolade service. Texts may be sent using
          an automatic telephone dialing system. Message and data rates may apply.
          You will receive one message per request. If you have any questions
          about your text plan or data plan, it is best to contact your wireless
          provider.
        </Body>
        <Body>
          <strong>WEB BROWSER</strong>
        </Body>
        <Body>
          The following web browsers are recommended for viewing the Site: Google
          Chrome, Microsoft Internet Explorer 8.0, or higher.
        </Body>
        <Body>
          <strong>GENERAL</strong>
        </Body>
        <Body>
          If any provision of these Terms of Use is found by a court of competent
          jurisdiction to be invalid, the parties nevertheless agree that the
          court should give effect to the parties’ intentions as reflected in the
          provision and the other provisions of the Terms of Use shall remain in
          full force and effect.
        </Body>
        <TitleMedium>
          <strong>CONTACT US</strong>
        </TitleMedium>
        <Body>
          If you have any questions, comments or concerns about the Site or these
          Terms of Use, please contact us as follows:
        </Body>
        <Body>
          <strong>For information on how to purchase Accolade services</strong>:
        </Body>
        <Body>
          <strong>EMAIL</strong>:&nbsp; Inquiries@accolade.com
          <br />
          <strong>SALES REQUEST FORM</strong>:&nbsp;{' '}
          <a href="https://www.accolade.com/contact">
            https://www.accolade.com/contact
          </a>
        </Body>
        <Body>
          <strong>PRIVACY CONCERNS:</strong>
        </Body>
        <Body>
          <strong>EMAIL</strong>: Privacy@accolade.com
          <br />
          <strong>MAIL</strong>: Accolade, Inc., 660 West Germantown Pike, Suite
          500 Plymouth Meeting, PA 19462
        </Body>
        <Body>
          <strong>SECURITY CONCERNS:</strong>
        </Body>
        <Body>
          <strong>EMAIL</strong>: Security@accolade.com
          <br />
          <strong>MAIL</strong>:<br />
          Accolade, Inc.,
          <br />
          660 West Germantown Pike, Suite 500
          <br />
          Plymouth Meeting, PA 19462
        </Body>
        <Body>
          <strong>General Website Questions</strong>:<br />
          <strong>EMAIL</strong>: Info@accolade.com
        </Body>
      </div>
    </Layout>
)
